import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import { Vue, Component } from 'vue-property-decorator'
import ResizableDirective from '@/components/ResizableDirective'
import FTable from '@/components/FTable'

@Component({
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    FTable,
  },
  directives: {
    resizable: new ResizableDirective()
  }
})

export default class Exchange extends Vue {
  columns = [
    [
      {
        label: 'Валута',
        field: 'valuta',
        width: 300,
        sortable: true,
        searchable: true,
      },
      {
        label: 'Земља',
        field: 'country',
        cellFormat: (row) => this.valute[row.valuta]
      },
      {
        label: 'Датум',
        field: 'datum',
        type: 'date',
      },
      {
        label: 'Важи за',
        field: 'paritet',
        sortable: true,
      },
      {
        label: 'Курс',
        field: 'kurs_mid',
        sortable: true,
      },
    ],
    [
      {
        label: 'Валута',
        field: 'valuta',
        width: 300,
        sortable: true,
        searchable: true,
      },
      {
        label: 'Земља',
        field: 'country',
        cellFormat: (row) => this.valute[row.valuta]
      },
      {
        label: 'Датум',
        field: 'datum',
        sortable: true,
      },
      {
        label: 'Важи за',
        field: 'paritet',
        sortable: true,
      },
      {
        label: 'Куповни курс',
        field: 'kurs_buy',
        sortable: true,
      },
      {
        label: 'Продајни курс',
        field: 'kurs_sel',
        sortable: true,
      },
    ],
    [
      {
        label: 'Валута',
        field: 'valuta',
        width: 300,
        sortable: true,
        searchable: true,
      },
      {
        label: 'Земља',
        field: 'country',
        cellFormat: (row) => this.valute[row.valuta]
      },
      {
        label: 'Датум',
        field: 'datum',
        sortable: true,
      },
      {
        label: 'Куповни курс',
        field: 'kurs_buy',
        sortable: true,
      },
      {
        label: 'Продајни курс',
        field: 'kurs_sel',
        sortable: true,
      },
      {
        label: 'Средњи курс',
        field: 'kurs_mid',
        sortable: true,
      },
    ]
  ];
  valute = {
    'EUR': 'ЕМУ',
    'AUD': 'Аустралија',
    'CAD': 'Канада',
    'CNY': 'Кина',
    'HRK': 'Хрватска',
    'CZK': 'Чешка Република',
    'DKK': 'Данска',
    'HUF': 'Мађарска',
    'JPY': 'Јапан',
    'KWD': 'Кувајт',
    'NOK': 'Норвешка',
    'RUB': 'Русија',
    'SEK': 'Шведска',
    'CHF': 'Швајцарска',
    'GBP': 'Велика Британија',
    'USD': 'САД',
    'BAM': 'Босна и Херцеговина',
    'PLN': 'Пољска',
    'BYN': 'Белоруска рубља',
    'RON': 'Румунски леј',
    'TRY': 'Турска лира',
    'BGN': 'Бугарски лев'
  };

  type = 0;
  instanceId = 0;

  get titleStack() {
    return [
      'Админ',
      'Курсна листа'
    ]
  }

  render() {
    return <div>
      <titleBar titleStack={this.titleStack} />
      <bTabs position="is-centered" destroyOnHide={true} class="block" vModel={this.type}>
        <bTabItem label="Средњи">
          <fTable
            title="Средњи курс"
            zero-message="Курс за изабрани датум није доступан ..."
            icon="elevation-rise"
            columns={this.columns[this.type]}
            endpoint="/kurs"
            key={this.instanceId}
            perPage={100}
            filter={{ tip: 3 }}
          >
          </fTable>
        </bTabItem>
        <bTabItem label="За девизе">
          <fTable
            title="За девизе"
            zero-message="Курс за изабрани датум није доступан ..."
            icon="elevation-rise"
            columns={this.columns[this.type]}
            endpoint="/kurs"
            key={this.instanceId}
            perPage={100}
            filter={{ tip: 1 }}
          >
          </fTable>
        </bTabItem>
        <bTabItem label="За ефективу">
          <fTable
            title="За ефективу"
            zero-message="Курс за изабрани датум није доступан ..."
            icon="elevation-rise"
            columns={this.columns[this.type]}
            endpoint="/kurs"
            key={this.instanceId}
            perPage={100}
            filter={{ tip: 2 }}
          >
          </fTable>
        </bTabItem>
      </bTabs>
    </div>
  }
}
